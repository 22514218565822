import PropTypes from 'prop-types'
import React from 'react'

const ContactArticle = (props) => (
    <article
        id="contact"
        className={`${props.isActive ? 'active' : ''} ${
            props.articleTimeout ? 'timeout' : ''
            }`}
        style={{ display: 'none' }}
    >
        <h2 className="major">{props.title}</h2>
        <form method="post" action="mailto:contact@krafted.tech">
            <div className="field half first">
                <label htmlFor="name">Name</label>
                <input type="text" name="name" id="name" />
            </div>
            <div className="field half">
                <label htmlFor="email">Email</label>
                <input type="text" name="email" id="email" />
            </div>
            <div className="field">
                <label htmlFor="message">Message</label>
                <textarea name="message" id="message" rows="4"></textarea>
            </div>
            <ul className="actions">
                <li>
                    <input type="submit" value="Send Message" className="special" />
                </li>
                <li>
                    <input type="reset" value="Reset" />
                </li>
            </ul>
        </form>
        {props.children}
    </article>
);

ContactArticle.prototype = {
    title: PropTypes.string,
    isActive: PropTypes.bool,
    articleTimeout: PropTypes.bool
}
export default ContactArticle