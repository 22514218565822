import PropTypes from 'prop-types'
import React from 'react'
import Navbar from './Navbar'
import Logo from '../assets/svgs/Colorlogo.svg'

const Header = props => (
  <header id="header" style={props.timeout ? { display: 'none' } : {}}>
    <div className="content">
      <div className="inner">
        <Logo width={props.isFirefox ? "50vw" : "100%"} height="100%" />
        <p>
          To make by hand and with much skill amazing software
        </p>
      </div>
    </div>
    <Navbar onOpenArticle={props.onOpenArticle} />
  </header>
)

Header.propTypes = {
  timeout: PropTypes.bool,
  isFirefox: PropTypes.bool,
  onOpenArticle: PropTypes.func
}

export default Header
