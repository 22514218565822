import PropTypes from 'prop-types'
import React from 'react'
import Articles from './Articles';

const Navbar = props => (
    <nav>
        <ul>
            <li>
                <button
                    onClick={() => {
                        props.onOpenArticle(Articles.Intro)
                    }}
                >
                    {Articles.Intro}
                </button>
            </li>
            <li>
                <button
                    onClick={() => {
                        props.onOpenArticle(Articles.Services)
                    }}
                >
                    {Articles.Services}
                </button>
            </li>
            <li>
                <button
                    onClick={() => {
                        props.onOpenArticle(Articles.Contact)
                    }}
                >
                    {Articles.Contact}
                </button>
            </li>
        </ul>
    </nav>
)

Navbar.prototype = {
    onOpenArticle: PropTypes.func
}

export default Navbar