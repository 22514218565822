import PropTypes from 'prop-types'
import React from 'react'

const Article = (props) => (
    <article
        id={props.title}
        className={`${props.isActive ? 'active' : ''} ${
            props.articleTimeout ? 'timeout' : ''
            }`}
        style={{ display: 'none' }}
    >
        <h2 className="major">{props.title}</h2>
        <span className="image main">
            <img src={props.pic} alt="" />
        </span>
        {props.children}
    </article>
)

Article.prototype = {
    title: PropTypes.string,
    isActive: PropTypes.bool,
    articleTimeout: PropTypes.bool,
    pic: PropTypes.img
}
export default Article