import PropTypes from 'prop-types'
import React from 'react'
import Article from './Article'
import Articles from './Articles'
import ContactArticle from './ContactArticle'
import banner1 from '../images/banner1.jpg'
import banner2 from '../images/banner2.jpg'

class Main extends React.Component {
  render() {
    let close = (
      <div
        className="close"
        onClick={() => {
          this.props.onCloseArticle()
        }}
      ></div>
    )

    return (
      <div
        ref={this.props.setWrapperRef}
        id="main"
        style={this.props.timeout ? { display: 'flex' } : { display: 'none' }}
      >
        <Article title={Articles.Intro} isActive={this.props.article === Articles.Intro} articleTimeout={this.props.articleTimeout} pic={banner1}>
          <p>
            Krafted TECH is a company founded by passion for software engineering. We believe software is like most products,
            it has a lifetime and we thrive to make it as long as possible. How ? By adopting the simplest solution.
            While finding the simplest solution is no easy task, boiling down your complicated challenge to a simple and clear product guarantees you understood your requirements.
          </p>
          <p>
            It's a long quest in a fast paced tech world but at Krafted TECH, we don't shy away from any challenge.
          </p>
          {close}
        </Article>
        <Article title={Articles.Services} isActive={this.props.article === Articles.Services} articleTimeout={this.props.articleTimeout} pic={banner2}>
          We assist you on your journey to create your next product by providing expertise in:
          <ul className="services">
            <li>
              <strong>Software development:</strong> Through a variety of technologies (.Net, Node, Angular, React)
            </li>
            <li>
              <strong>Audit:</strong> Full review of your software architecture, code and practices to prepare your next project (refactoring, migration, ..)
            </li>
            <li>
              <strong>Training:</strong> Onsite coaching about software craftsmanship (TDD, BDD, Clean architecture) and technologies like Angular, .Net and Node
            </li>
          </ul>
          {close}
        </Article>
        <ContactArticle title={Articles.Contact} isActive={this.props.article === Articles.Contact} articleTimeout={this.props.articleTimeout} >
          {close}
        </ContactArticle>
      </div>
    )
  }
}

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
}

export default Main
