import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEnvelope } from "@fortawesome/free-regular-svg-icons"
import { faLinkedinIn } from "@fortawesome/free-brands-svg-icons"
import { faPhone } from "@fortawesome/free-solid-svg-icons"

const Footer = (props) => (
    <footer id="footer" style={props.timeout ? { display: 'none' } : {}}>
        <p className="copyright">&copy; Krafted TECH {new Date().getFullYear()}</p>
        <ul className="icons">
            <li>
                <a
                    href="https://www.linkedin.com/company/krafted-tech"
                    className="icon"
                    alt="Linkedin"
                >
                    <FontAwesomeIcon icon={faLinkedinIn} />
                    <span className="label">Linkedin</span>
                </a>
            </li>
            <li>
                <a target="_blank" rel="noopener noreferrer" href="mailto:contact@krafted.tech" className="icon" alt="Mail">
                    <FontAwesomeIcon icon={faEnvelope} />
                    <span className="label">Mail</span>
                </a>
            </li>
            <li>
                <a href="tel:+33649524217" className="icon" alt="Mobile">
                    <FontAwesomeIcon icon={faPhone} />
                    <span className="label">Mobile</span>
                </a>
            </li>
        </ul>
    </footer>
)

Footer.propTypes = {
    timeout: PropTypes.bool
}

export default Footer
